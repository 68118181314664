<template>
  <div  :class="$style.headerContainer">
    <div class="container">
      <div :class="$style.divHeader" ref="divHeader">
        <div class="row" :class="$style.divTopMenu">
          <div class="col-md-12">
            <div :class="$style.divInnerLogo" ref="divInnerLogo" @click="home">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import tippy from 'tippy.js';
  import { mapState } from 'vuex';
  // import HelpDesk from '@/components/HelpDesk';
  import tools from '@/tools';
  import config from '../../config';

  export default {
    name: 'headerCustom',
    data() {
      return {
        wsCode: null,
      };
    },
    methods: {
      logout() {
        tools.cookie.set(config.global_key.tokenName, '', 0);
        tools.cookie.set(config.global_key.mcourserTokenName, '', 0);
        localStorage.clear();
        window.location = '/';
      },
      back() {
        this.$router.go(-1);
      },
      home() {
        this.$router.push('/home');
      },
    },
    props: ['isHome'],
    computed: {
      ...mapState(['roleType', 'grade', 'username', 'password']),
    },
    components: {
      // HelpDesk,
    },
    mounted() {
      tippy('[title]');
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style lang="less" scoped>
  button {
    outline: 0 !important;
    border: 0 !important;
  }

  .nav-item {
    color: white !important;
  }

  .nav-item:hover {
    background-color: white;
    color: #21468b !important;
  }

  .nav-item:hover>.fa {
    color: #21468b !important;
  }

  .navbar-brand {
    margin-right: none;
  }

</style>
