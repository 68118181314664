<template>
  <div :title="scormDesc" :class="$style.divCard">
    <div :class="$style.customCard" @click="goToContent()">
      <div class="row" :class="$style.topCard">
        <div class="col-md-12">
          <img :src="imgpath" alt="Content Cover" :class="$style.coverImg" class="cardCoverImg" onerror="this.src='https://cdn0.iconfinder.com/data/icons/seo-smart-pack/128/grey_new_seo-37-512.png'">
        </div>
        <div class="col-md-12">
          <h1 :class="$style.contentTitle">{{ scormTitle ? scormTitle : title }}</h1>
          <div class="row justify-content-center">
            <div>
              <span :class="$style.divState" v-if="stateName === 'Not Started'" class="badge badge-light">{{stateName}}</span>
              <span :class="$style.divState" v-else-if="stateName === 'Ongoing'" class="badge badge-info">{{stateName}}</span>
              <span :class="$style.divState" v-else-if="stateName === 'Completed'" class="badge badge-success">{{stateName}}</span>
              <span :class="$style.divState" v-else-if="stateName === 'Expired'" class="badge badge-danger">{{stateName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center mt-3" :class="$style.bottomCard" v-show="this.contentType === 'scorm'">
        <span v-if="this.$store.state.roleType === 'student'" :class='[$style.customBadge, $style.customBadgeFirst]' class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5 offset-sm-1 col-xs-5 offset-xs-1 badge badge-primary">Score</span>
        <span v-if="this.$store.state.roleType === 'student'" :class='$style.customBadge' class="col-lg-6 col-md-5 col-sm-5 col-xs-6 badge">{{parseInt(score)}}%</span>
        <span v-if="this.$store.state.roleType === 'student' && this.packageType === 'assignment'" :class='[$style.customBadge, $style.customBadgeFirst]' class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5  offset-sm-1  col-xs-5  offset-xs-1  badge badge-info">Due Date</span>
        <span v-else-if="date" :class='[$style.customBadge, $style.customBadgeFirst]' class="col-lg-5 offset-lg-1 col-md-5 offset-md-1 col-sm-5  offset-sm-1  col-xs-5  offset-xs-1  badge badge-info">Due Date</span>
        <span v-if="date" :class='$style.customBadge' class="col-lg-6 col-md-5 col-sm-5 col-xs-5 badge">{{ convertDate(date) }}</span>
        <!-- <div v-if="this.roleType === 'teacher'" class="col-md-6 col-sm-6">
          <button v-if="this.userDetails.lang === 'de'" class="btn" :class="$style.btnPreview">Vorschau</button>
          <button v-else class="btn" :class="$style.btnPreview">Preview</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import tippy from 'tippy.js';

  export default {
    name: 'card',
    data() {
      return {
        routerObj: {},
      };
    },
    props: [
      'title',
      'imgpath',
      'score',
      'date',
      'contentType',
      'id',
      'packageType',
      'stateName',
      'scormTitle',
      'scormCode',
      'scormDesc',
      'linkUrl',
      'packageId',
      'coverImageHeight',
    ],
    mounted() {
      this.setRouterObject();
      this.setCoverImageHeight();
    },
    updated() {
      this.setRouterObject();
      this.setCoverImageHeight();
    },
    computed: {
      ...mapState(['roleType', 'userDetails']),
    },
    methods: {
      goToContent() {
        if (this.contentType === 'link') {
          window.open(this.linkUrl, '_blank');
        } else {
          this.$router.push(this.routerObj);
        }
      },
      setRouterObject() {
        if (this.contentType === 'scorm') {
          tippy('[title]');
          if (this.roleType === 'student') {
            this.routerObj = {
              name: 'assignmentDetails',
              params: { id: this.packageId, community: 'individual' },
            };
          } else if (this.roleType === 'teacher') {
            this.routerObj = {
              name: this.contentType,
              params: {
                id: this.id,
                packageId: this.packageId,
                scormTitle: this.scormTitle,
                scormDesc: this.scormDesc,
                scormCode: this.scormCode,
                packageType: null,
                timestamp: null,
              },
            };
          }
        } else if (this.contentType === 'free') {
          this.routerObj = {
            name: 'scorm',
            params: {
              id: this.id,
              packageId: this.packageId,
              scormTitle: this.scormTitle,
              scormDesc: this.scormDesc,
              scormCode: this.scormCode,
              timestamp: null,
            },
          };
        } else {
          this.routerObj = {
            name: this.contentType === 'collectionsPackage' ? 'collections' : this.contentType,
            params: {
              id: this.id,
              searchList: this.contentType === 'collectionsPackage' ? 'packages' : 'collections',
            },
          };
        }
      },
      setCoverImageHeight() {
        if (this.coverImageHeight) {
          const covers = document.getElementsByClassName('cardCoverImg');
          for (const item of covers) {  //eslint-disable-line
            item.style.minHeight = this.coverImageHeight;
            item.style.width = 'auto';
          }
        }
      },
      convertDate(inputFormat) {
        function pad(s) {
          return (s < 10) ? `0${s}` : s;
        }
        const fixDateForAllBrowsers = inputFormat.replace(/-/g, '/');
        const d = new Date(fixDateForAllBrowsers);
        const first = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
        // const second = [pad(d.getHours()), pad(d.getMinutes() + 1), d.getSeconds()].join(':');
        return `${first}`;
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>
