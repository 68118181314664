<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">

        <div class="col-md-4 offset-md-4">
          <Search :searchList='this.data' @search='search' ref="txtSearch"></Search>

        </div>
        <div class="col-md-12">
          <h1 class="title menuTitle" :class='$style.menuTitle'>{{title}}</h1>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-2 col-md-4 col-sm-12 mt-1">
            <button :disabled="this.selectedAssignments.length > 0 ? false : true" class="btn" :class="$style.otherButtons" @click="remove()"><i class="fa fa-trash"></i> Remove</button>
          </div>
          <div class="col-lg-2 offset-lg-6 col-md-4 col-sm-12 mt-1 ">
            <button class="btn" :class="$style.createAssignmentBtn" @click="goTo('/assignment/report')"><i class="fa fa-table"></i> Report</button>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-12 mt-1 ">
            <button class="btn" :class="$style.createAssignmentBtn" @click="goTo('/assignment/create')"><i class="fa fa-plus-circle"></i> Create</button>
          </div>
        </div>
      <ul class="nav nav-tabs mt-5 row">
        <li class="nav-item col">
          <a class="nav-link active" @click="updateView('individual')" href="#" ref="individual">Individual Assignments</a>
        </li>
        <li class="nav-item col">
          <a class="nav-link" @click="updateView('group')" href="#" ref="group">Group Assignments</a>
        </li>
        <!-- <li class="nav-item col">
          <a class="nav-link" @click="updateView('completed')" href="#" ref="completed">Completed Assignments</a>
        </li> -->
      </ul>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div v-else>
        <div v-if="this.activeDataSet.length > 0" :class="$style.columns" class="table-responsive">
          <table class="table table-hover table-bordered" :class="$style.customTable">
            <thead :class="$style.customThead">
              <tr>
                <th>#</th>
                <th v-if="activeTab !== 'completed'">
                  <input type="checkbox" @click="cbClicked" class="check" id="allCheckBox">
                </th>
                <th>Assignment Name</th>
                <th>Lesson Name</th>
                <th>Assignee</th>
                <th>Start Date</th>
                <th>Due Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody v-for="(item,i) in this.activeDataSet" :key="i">
              <tr class="table-light">
                <!-- <td>{{(currentPageIndex * dataCount) + i+1}}</td> -->
                <td>{{i+1}}</td>
                <td v-if="activeTab !== 'completed'">
                  <input type="checkbox" class="check" @click="cbClicked" :value="item.assignment_id">
                </td>
                <td>{{item.assignment_name}}</td>
                <td>{{item.scorm.scorm_name}}</td>
                <td>{{item.name}}</td>
                <td>{{ convertDate(item.start_date) }}</td>
                <td>{{ convertDate(item.end_date) }}</td>
                <td>
                  <router-link 
                  :to="{ name: 'assignmentDetails', params: { id: item.assignment_id, community: item.assignment_type }}"
                  >
                    <button class="btn" :class="$style.btnDetails" title="Details"> 
                    <i class="fa fa-info-circle fa-1x"></i>
                    </button>
                  </router-link>
                </td>
              </tr>

            </tbody>
          </table>
          <!-- <div class="col-md-2 offset-md-10" :class="$style.divPagination">
            <a v-if="(totalIndividualAssignmentCount/dataCount) > currentPageIndex+1" href='#' class="btn pull-right" :class="$style.btnPage"
              @click="incCurrentPageIndex">
              <i class="fa fa-arrow-right"></i>
            </a>
            <a v-if="this.currentPageIndex !== 0" href='#' class="btn pull-right" :class="$style.btnPage" @click="decCurrentPageIndex">
              <i class="fa fa-arrow-left"></i>
            </a>
          </div> -->
        </div>
        <div v-else>
          <p :class="$style.noContent">There is no Assignment.</p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  /*eslint-disable*/
  import swal from 'sweetalert';
  import tippy from 'tippy.js';

  import { mapActions, mapState } from 'vuex';
  import HeaderCustom from '@/components/HeaderCustom';
  import Search from '@/components/Search';
  import InnerLoading from '@/components/InnerLoading';

  import contentService from '@/services/content';

  export default {
    name: 'assignments',
    data() {
      return {
        data: [],
        activeTab: '',
        activeDataSet: [],
        title: '',
        contentType: '',
        selectedAssignments: [],
        searchText: '',
        tabData: ['individual', 'group'],
        ready: false,
      };
    },
    components: {
      HeaderCustom,
      Search,
      InnerLoading,
    },
    computed: {
      ...mapState(['assignments']),
    },
    watch: {
      assignments() {
        this.updateView();
      },
    },
    async beforeMount() {
      this.contentType = 'lesson';
      this.title = 'ASSIGNMENTS';
      await this.getLmsAssignments();
      await this.getCollections();
      await this.getScorms();
      await this.getStudentsUnselected();
      this.updateView();
      this.ready = true;
    },
    mounted() {
      tippy('[title]');
    },
    methods: {
      ...mapActions(['getLmsAssignments', 'getCollections', 'getScorms', 'getStudentsUnselected']),
      updateView(tabParam) {
        if (!tabParam) {
          tabParam = 'individual';
        }
        this.activeTab = tabParam;
        this.unselectAllSelectedLessons();
        this.setTabView();
        this.setTabData();
      },
      setTabView() {
        //* tabData yı kullanrak tüm tabları normale çek ardından aktif olanı active çek
        this.tabData.forEach(element => {
          this.$refs[element].classList = 'nav-link';
        });
        this.$refs[this.activeTab].classList = 'nav-link active';
      },
      setTabData() {
        //* Datayı aktif olan taba göre şekillendir (eğer dolu ise searchText de dikkate alınır)
        this.activeDataSet = this.assignments.filter(x => x.assignment_type === this.activeTab &&
        (x.assignment_name.toLocaleLowerCase('tr-TR').includes(this.searchText) ||
          x.scorm.scorm_name.toLocaleLowerCase('tr-TR').includes(this.searchText) ||
          x.name.toLocaleLowerCase('tr-TR').includes(this.searchText)));
      },
      unselectAllSelectedLessons() {
        this.selectedAssignments = [];
        const allChecks = document.getElementsByClassName('check');
        for (const item of allChecks) {
          item.checked = false;
        }
      },
      goTo(param) {
        this.$router.push(param);
      },
      convertDate(inputFormat) {
        function pad(s) {
          return (s < 10) ? `0${s}` : s;
        }
        const fixDateForAllBrowsers = inputFormat.replace(/-/g, '/');
        const d = new Date(fixDateForAllBrowsers);
        const first = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
        // const second = [pad(d.getHours()), pad(d.getMinutes() + 1), d.getSeconds()].join(':');
        return `${first}`;
      },
      search(text) {
        if (!text) {
          this.searchText = '';
        } else {
          this.searchText = text.toLocaleLowerCase('tr-TR');
        }
        this.updateView(this.activeTab);
      },
      cbClicked(e) {
        if (e.target.id === 'allCheckBox') {
          //* Tüm assignmentlar tıklandı ise
          this.selectedAssignments = [];
          const allcb = document.getElementById('allCheckBox');
          const state = allcb.checked;
          const allChecks = document.getElementsByClassName('check');
          //* state true ise tüm checkboxlar seçili false ise tamamı boş olacak
          for (const item of allChecks) {
            item.checked = state;
            if (item.value !== 'on' && state) {
              this.selectedAssignments.push(item.value);
            }
          }
          if (!state) {
            this.selectedAssignments = [];
          }
        } else {
          //* Tek bir assignment tıklandı ise
          if (e.target.checked) {
            //* Tek assignment seçiliş ise selectedAssignments arrayinde yoksa ekle ki seçili olmadığı durumda çıkarılacak yok olduğu farz edilebilir çünkü tik kaldırılınca diziden silinecek
            this.selectedAssignments.push(e.target.value);
          } else if (!e.target.checked) {
            //* tiki kaldırılan checkboxı arrayden sil
            let checkAssignment = e.target.value.split(',');
            checkAssignment.forEach(element => {
                const i = this.selectedAssignments.indexOf(element);
                this.selectedAssignments.splice(i, 1);
            });
          }
        }
      },
      async remove() {
        swal({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this assignments!',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            contentService.deleteAssignments(this.selectedAssignments).then((res) => {
              if(!res.success) {
                console.log('Assignment Delete Error', res.data);
                swal('','An error occured while you removing assignments', 'error');
              } else {
                swal('Assignments', 'Removed successfully!', 'success').then(() => {
                  this.selectedAssignments = [];
                  location.reload();
                });
              }
            });        
          } else {
            this.ready = true;
          }
        });
      },
    },
    destroyed() {
      this.unselectAllSelectedLessons();
    },
  };
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  .nav-tabs {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
</style>