<template>
  <section>
    <HeaderCustom isHome='true'></HeaderCustom>
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-12">
          <img src="@/assets/img/cover.jpg" alt="" class="w-100">
        </div>
      </div>
      <div class="row mt-5">
          <div class="col-md-4 mb-1" :class="[$style.divHedef, $style.divHedef1]" @click="goToExam(0)">
           <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 col-xs-12" :class="$style.icon">
              <img src="@/assets/img/clock.png">
            </div>
						<span class="col-md-12 col-xs-12" :class="$style['description-top']">Seviye Tespit Sınavı 1</span>
						<span class="col-md-12 col-xs-12" :class="$style['description-bottom']">6-12 Yaş</span>
          </div>
        </div>
        <div class="col-md-4 mb-1" :class="[$style.divHedef, $style.divHedef2]" @click="goToExam(5)">
           <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 col-xs-12" :class="$style.icon">
              <img src="@/assets/img/clock2.png">
            </div>
						<span class="col-md-12 col-xs-12" :class="$style['description-top']">Seviye Tespit Sınavı 2</span>
						<span class="col-md-12 col-xs-12" :class="$style['description-bottom']">+12 ve Üstü</span>
          </div>
        </div>
        <div class="col-md-4 mb-1" :class="[$style.divHedef, $style.divHedef3]" @click="$router.push({ name: 'mycontents' });">
           <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 col-xs-12" :class="$style.icon">
              <img src="@/assets/img/course.png">
            </div>
						<span class="col-md-12 col-xs-12" :class="$style['description-top']">Dersler</span>
          </div>
        </div>
      </div>
      <div :class="$style.footer">
        Unlimited Educational Services&reg;
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import tools from '@/tools';
  import config from '@/config';
  import HeaderCustom from '@/components/HeaderCustom';
  import authService from '../../services/auth';

  export default {
    name: 'home',
    data() {
      return {
        ready: false,
      };
    },
    components: {
      HeaderCustom,
    },
    computed: {
      ...mapState(['assessments']),
      isStudent() {
        const role = this.$store.state.roleType;
        if (role === 'student') {
          return true;
        }
        return false;
      },
      isTeacher() {
        const role = this.$store.state.roleType;
        if (role === 'teacher') {
          return true;
        }
        return false;
      },
    },
    methods: {
      goToExam(level) {
        // CCE ASSESSMENT
        const examPackage = this.assessments.find(x => x.package_scorms.length > 0);
        const examScorm = examPackage.package_scorms[level];
        const routerObj = {
          name: 'scorm',
          params: {
            id: examScorm.scorm_id,
            packageId: examPackage.package_id,
            scormTitle: examScorm.scorm_name,
            scormDesc: examScorm.scorm_desc,
            scormCode: examScorm.scorm_code,
            timestamp: null,
          },
        };
        this.$router.push(routerObj);
      },
      logout() {
        tools.cookie.set(config.global_key.tokenName, '', 0);
        tools.cookie.set(config.global_key.mcourserTokenName, '', 0);
        localStorage.clear();
        window.location = '/';
      },
    },
    async beforeMount() {
      const res = await authService.checkToken();
      if (!res.success) {
        const refreshRes = await authService.refreshToken();
        if (!refreshRes.success) {
          this.logout();
        }
      }
    },
  };
</script>

<style src='./style.less' lang='less' module/>