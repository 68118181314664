import axios from 'axios';
import uesLms from '../services';
import tools from '../tools';
import config from '../config';
// import store from '../stores';

export default {
  async login(token) {
    try {
      await tools.cookie.set(config.global_key.tokenName, token);
      // const res = await axios.post('https://www.ueslms.com/api/v2/user', {
      //   headers: {
      //     // eslint-disable-next-line quote-props
      //     'Authorization': `Bearer ${token}`,
      //   },
      // });
      const res = await uesLms().post('/user');
      if (res.message === 'Request failed with status code 401') {
        return {
          success: false,
        };
      }
      await tools.cookie.set(config.global_key.tokenName, token);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async checkToken() {
    try {
      const res = await uesLms().post('/user');
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async refreshToken() {
    try {
      const oldToken = tools.cookie.get(config.global_key.tokenName);
      const res = await axios.post('https://www.ueslms.com/api/v2/authenticate/refreshToken', {
        token: oldToken,
      });
      await tools.cookie.set(config.global_key.tokenName, res.data.newToken);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async loginLog(agent, ip) {
    try {
      const res = await uesLms().post('/user/loginLog', {
        api_key: config.apiKey,
        user_agent: agent,
        ip_address: ip,
      });
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
};
