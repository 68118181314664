<template>
  <div>
    <div class="row" :class="$style.customRow">
      <div class="col-md-8 offset-md-1">
        <v-jstree :data="treeArr" show-checkbox multiple allow-batch whole-row @item-click="itemClick"></v-jstree>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

/*eslint-disable*/
export default {
  name: 'thirdStep',
  data() {
    return {
      treeData: [],
    };
  },
  computed: {
    ...mapState(['students']),
    treeArr() {
      return this.treeData;
    },
  },
  methods: {
    itemClick(node) {
      if (node.model.type === 'student' && !node.model.selected) {
        const gradeData = this.treeData.find(
          x => x.value === node.model.parentValue
        );
        this.treeData.find(
          x => x.value === node.model.parentValue
        ).selected = false;
      } else if (node.model.type === 'student' && node.model.selected) {
        const gradeData = this.treeData.find(
          x => x.value === node.model.parentValue
        );
        let isAllSelected = true;
        for (const item of gradeData.children) {
          if (!item.selected) {
            isAllSelected = false;
            break;
          }
        }
        if (isAllSelected) {
          this.treeData.find(
            x => x.value === node.model.parentValue
          ).selected = true;
        }
      }
    },
  },
  beforeMount() {
    this.treeData = this.students;
  },
  beforeDestroy() {
    const obj = {};
    let classArr = [];
    let stuArr = [];
    for (const item of this.treeData) {
      if (item.selected) {
        classArr = [...classArr, item.value];
      } else {
        stuArr = [
          ...stuArr,
          ...item.children.filter(x => x.selected).map(x => x.value),
        ];
      }
    }
    if (classArr.length > 0 && stuArr.length > 0) {
      obj.assignment_type = 'mixed';
      obj.studentIds = stuArr;
      obj.groupIds = classArr;
    } else if(classArr.length > 0) {
      obj.assignment_type = 'group';
      obj.ids = classArr;
    } else if(stuArr.length > 0) {
      obj.assignment_type = 'individual';
      obj.ids = stuArr;
    }
    this.$parent.setAssignmentData(obj);
  },
};
</script>

<style src='./style.less' lang='less' module/>
