module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#00247e',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'bnO3al7gSC4hcHZYgrm3hhFZ7Jex2954wmT8l9Cs3GOExCT0',
};
