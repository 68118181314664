<template>
  <div :class="$style.divLogin">
    <div v-if="!ready" :class="$style.lmsLoading"></div>
    <div :class="$style.loginform">
      <div>
        <div :class="$style.logo"> </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import swal from 'sweetalert';
  import config from '@/config';
  import authService from '@/services/auth';
  import axios from 'axios';

  export default {
    name: 'login',
    data() {
      return {
        ready: true,
        username: '',
        password: '123456',
        isChecked: false,
        primaryColor: config.primaryColor,
        firstName: '',
        lastName: '',
        ipAdress: '',
      };
    },
    async beforeMount() {
      try {
        localStorage.clear();
        if (this.$route.query.code) {
          const token = this.$route.query.code;
          this.login(token);
        }
      } catch (error) {
        console.log(error);
        swal('Invalid Token!', '', 'error');
      }
    },
    methods: {
      ...mapActions(['getPackages', 'getPackagesAssignments']),
      async login(token) {
        this.ready = false;
        const res = await this.$store.dispatch('login', token);
        if (res.success) {
          await this.getPackages();
          await this.getPackagesAssignments();
          const ipData = await axios.get("https://api.ipify.org?format=json")
          .catch(err => {
              console.log('err', err);
          });

          this.ipAdress = ipData ? ipData.data.ip : '127.0.0.1';

          const userAgent = navigator.userAgent;
          await authService.loginLog(userAgent, this.ipAdress).then(() => {
            this.$router.push('/home');
          }).catch(err => console.log('err', err));
        } else {
          swal('Error!', 'Token is invalid.', 'error');
        }
        this.ready = true;
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style>
  .abcRioButton {
    width: 300px !important;
  }

</style>
