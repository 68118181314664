import uesLms from '../services';

export default {
  async getPackages() {
    try {
      const res = await uesLms().post('/packages', {
        package_type: 'free',
      });
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getPackagesAssignments() {
    try {
      const res = await uesLms().post('/packages', {
        package_type: 'assessment',
      });
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getAssignments() {
    try {
      const res = await uesLms().get('/assignments');
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getCollections() {
    try {
      const res = await uesLms().get('/collections');
      if (res.response && res.response.status !== 200) {
        console.log('Collection Error', res.response.data.error);
        res.data = [];
      }
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getScorms() {
    try {
      const res = await uesLms().get('/scorms');
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async setAssignment(data) {
    try {
      const res = await uesLms().post('/assignment/create', data);
      if (res.data.success === false) {
        return {
          success: false,
          data: res.data.message,
        };
      }
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async deleteAssignments(ids) {
    try {
      /*eslint-disable*/
      let removeSuccess = false;
      let globalRes = '';
      for (const id of ids) {
        const res = await uesLms().get(`/assignment/${id}/deleteassignment`);
        if(res.data.success === true) {
          removeSuccess = true;
        } else {
          removeSuccess = false;
          globalRes = res.data.message;
          break;
        }
      }
      if (removeSuccess === false) {
        return {
          success: false,
          data: globalRes,
        };
      }
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getPaginatedScores(id) {
    try {
      const res = await uesLms().get(`/assignment/${id}`);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async loadState(data) {
    try {
      const res = await uesLms().post('/state/load', data);
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async saveStateScore(data) {
    try {
      const res = await uesLms().post('/state/save', data);
      return res.data;
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
};
