<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <Search></Search>
        </div>
        <div class="col-md-12">
          <h1 class="title menuTitle" :class='$style.menuTitle'>{{title}}</h1>
        </div>
      </div>
      <div v-if="!ready">
        <InnerLoading></InnerLoading>
      </div>
      <div v-else-if="this.data.length > 0" :class="$style.columns">
        <div class="row" v-for="(parent,k) in data.filter(x => x.package_template_id === 0 && x.package_links.length === 0)" :key="k">
          <div class="col-md-12">
            <h5>{{parent.package_name}}</h5>
            <hr>
          </div>
          <div class="col-md-4" :class="$style.column" v-for="(child,i) in data.filter(x => x.package_id === parent.package_id)"
            :key="i">
            <Card :contentType='contentType' :packageType='child.package_type' :id='child.package_id' :title='child.package_name' 
            :imgpath='child.package_image ? child.package_image : "https://cdn0.iconfinder.com/data/icons/seo-smart-pack/128/grey_new_seo-37-512.png"'
              :score='child.package_avg_score' :date='child.package_end_date'></Card>
          </div>
        </div>
      </div>
      <div v-else>
        <p :class="$style.noContent">There is no content here.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import _ from 'lodash';

  import Card from '@/components/Card';
  import Search from '@/components/Search';

  export default {
    name: 'mycontents',
    data() {
      return {
        data: [],
        title: '',
        contentType: '',
        ready: false,
      };
    },
    components: {
      HeaderCustom,
      Card,
      Search,
      InnerLoading,
    },
    computed: {
      ...mapState(['freeContents']),
    },
    async beforeMount() {
      this.title = 'RESOURCES';
      // await this.getPackages();
      this.data = _.orderBy(this.freeContents, ['package_id']);
      console.log('data', this.data);
      this.contentType = 'lessons';
      this.ready = true;
    },
    methods: {
      ...mapActions(['getPackages', 'getCollections', 'getScorms']),
      search(text) {
        if (!text) {
          this.data = this.freeContents;
        } else {
          /*eslint-disable*/
          this.data = this.freeContents.filter(x =>
            x.package_name.toLowerCase().includes(text.toLowerCase())
          );
        }
      },
    },
  };
</script>

<style src='./style.less' lang='less' module/>