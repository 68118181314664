<template>
    <div>
        <HeaderCustom></HeaderCustom>
        <div :class="$style.divReport" class="container">
          <div class="row justify-content-center mt-5">
            <div :class="$style.title">Get Assignments Report</div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-2">Assignment Type:</div>
            <div class="col-md-5">
              <v-select v-model="selectedType" label='name' :options="this.assignmentTypes"></v-select>
            </div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-2">Assignee:</div>
            <div class="col-md-5">
              <v-select v-model="selectedAssignee" label='name' :options="this.assigneesArr"></v-select>
            </div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-2">Assignments:</div>
            <div class="col-md-5">
              <v-select v-model="selectedAssignments" multiple label='lesson_name' :options="this.assignmentsArr"></v-select>
            </div>
          </div>
          <div class="row mt-2">
            <button disabled class="col-md-2 offset-md-7 btn" :class='$style.btnReport' @click='getReport' id="btnReport">Get Report</button>
            <button disabled class="col-md-2 btn ml-2" :class='$style.btnReport' @click='print' id="btnPrint">Print</button>
          </div>
        <div class="row" v-if="tableData.length > 0" id="section-to-print">
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-bordered" :class="$style.customTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Student Name</th>
                  <th>Total Checks</th>
                  <th>Total Errors</th>
                  <th>Total Mistakes</th>
                  <th>Average Score</th>
                  <th>Total Time</th>
                </tr>
              </thead>
              <tbody v-for="(item,i) in tableData" :key="i">
                <tr>
                  <td>{{ i+1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.total_checks_count }}</td>
                  <td>{{ item.total_errors_count }}</td>
                  <td>{{ item.total_mistake_count }}</td>
                  <td>{{ item.avg_score.toFixed(0) }}%</td>
                  <td>{{ millisToMinutesAndSeconds(item.total_time) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import HeaderCustom from '@/components/HeaderCustom';
import contentService from '@/services/content';

export default {
  name: 'assignmentReport',
  data() {
    return {
      assignmentTypes: ['Group', 'Individual'],
      selectedType: 'Select Type',
      assignmentType: '',
      selectedAssignee: 'Select Type',
      assigneesArr: [],
      assignmentsArr: [],
      selectedAssignments: [],
      tableData: [],
    };
  },
  computed: {
    ...mapState(['assignments']),
  },
  components: {
    HeaderCustom,
  },
  watch: {
    selectedType(e) {
      this.selectedAssignee = 'Select an Assignee';
      this.assignmentType = e.toLowerCase();
      this.assigneesArr = _.uniqBy(this.assignments
        .filter(x => x.assignment_type === this.assignmentType), 'name').map(x => x.name);
    },
    selectedAssignee(e) {
      this.selectedAssignments = [];
      if (e !== 'Select an Assignee') {
        this.assignmentsArr = this.assignments.filter(x => x.name === e).map(x => ({
          assignment_name: x.assignment_name,
          lesson_name: x.scorm.scorm_name,
          id: x.assignment_id,
        }));
      }
    },
    selectedAssignments(e) {
      if (e.length > 0) {
        document.getElementById('btnReport').disabled = false;
      } else {
        document.getElementById('btnReport').disabled = true;
      }
    },
  },
  methods: {
    async getReport() {
      this.tableData = [];
      const assignmentDatas = [];
      for (const item of this.selectedAssignments) {  //eslint-disable-line
        const assignmentData = this.assignments.find(x => x.assignment_id === item.id);
        if (this.assignmentType === 'group') {
          const groupAssignmentScores = await contentService.getPaginatedScores(assignmentData.assignment_id); //eslint-disable-line
          if (groupAssignmentScores.success) {
            assignmentDatas.push(groupAssignmentScores.data);
          }
        } else {
          assignmentDatas.push(assignmentData);
        }
      }
      let allAssignments = [];
      if (this.assignmentType === 'group') {
        for (const item of assignmentDatas) { //eslint-disable-line
          allAssignments = [...allAssignments, ...item.users];
        }
        for (const item of allAssignments) { //eslint-disable-line
          const userScores = allAssignments.filter(x => x.user_id === item.user_id);
          let totalCheck = 0;
          let totalErrors = 0;
          let totalMistake = 0;
          let totalScore = 0;
          let totalTime = 0;
          for (let i = 0; i < userScores.length; i += 1) {
            const element = userScores[i];
            totalCheck += element.scorm_scores.total_checks_count;
            totalErrors += element.scorm_scores.total_errors_count;
            totalMistake += element.scorm_scores.total_mistake_count;
            totalScore += element.scorm_scores.total_score;
            totalTime += element.scorm_scores.total_time;
          }
          const obj = {};
          obj.user_id = userScores[0].user_id;
          obj.name = userScores[0].name;
          obj.total_checks_count = totalCheck;
          obj.total_errors_count = totalErrors;
          obj.total_mistake_count = totalMistake;
          obj.avg_score = totalScore / userScores.length;
          obj.total_time = totalTime;
          this.tableData.push(obj);
        }
        this.tableData = _.uniqBy(this.tableData, 'user_id');
      } else if (this.assignmentType === 'individual') {
        let totalCheck = 0;
        let totalErrors = 0;
        let totalMistake = 0;
        let totalScore = 0;
        let totalTime = 0;
        for (let i = 0; i < assignmentDatas.length; i += 1) {
          const element = assignmentDatas[i];
          totalCheck += element.scorm.scorm_scores.total_checks_count;
          totalErrors += element.scorm.scorm_scores.total_errors_count;
          totalMistake += element.scorm.scorm_scores.total_mistake_count;
          totalScore += element.scorm.scorm_scores.total_score;
          totalTime += element.scorm.scorm_scores.total_time;
        }
        const obj = {};
        obj.name = assignmentDatas[0].name;
        obj.total_checks_count = totalCheck;
        obj.total_errors_count = totalErrors;
        obj.total_mistake_count = totalMistake;
        obj.avg_score = totalScore / assignmentDatas.length;
        obj.total_time = totalTime;
        this.tableData.push(obj);
      }
      document.getElementById('btnPrint').disabled = false;
    },
    millisToMinutesAndSeconds(millis) {
      let minutes = Math.floor(millis / 60000);
      let seconds = ((millis % 60000) / 1000).toFixed(0);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `${minutes}`;
      }
      return `${minutes}:${seconds}`;
    },
    print() {
      window.print();
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0px;
  }
}
</style>
