import axios from 'axios';
import tools from '@/tools';
import configuration from '@/config';
import router from '../router';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const token = tools.cookie.get(configuration.global_key.tokenName);
  config.baseURL = 'https://www.ueslms.com/api/v2';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(res => res, (err) => {
  if (err.message === 'Request failed with status code 500') {
    router.push('/home');
  }
  throw err;
});
export default () => instance;
