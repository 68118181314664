import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login';
import Home from '@/components/Home';
import StudentAssignments from '@/components/Assignments/Student';
import TeacherAssignments from '@/components/Assignments/Teacher';
import AssignmentDetails from '@/components/Assignments/Details';
import AssignmentCreate from '@/components/Assignments/Create';
import AssignmentReport from '@/components/Assignments/Report';
import Lessons from '@/components/Lessons';
import Scorm from '@/components/Scorm';
import MyContents from '@/components/MyContents';
import Collections from '@/components/Collections';
import Scorms from '@/components/Scorms';
// import SpeedTest from '@/components/SpeedTest';

import tools from '@/tools';
import store from '../stores';

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/assignments',
      name: 'studentAssignments',
      component: StudentAssignments,
      beforeEnter: (to, from, next) => {
        if (store.state.roleType === 'teacher') {
          next({
            name: 'teacherAssignments',
          });
        }
        next();
      },
    },
    {
      path: '/assignments',
      name: 'teacherAssignments',
      component: TeacherAssignments,
    },
    {
      path: '/mycontents',
      name: 'mycontents',
      component: MyContents,
    },
    {
      path: '/mycontents/:id',
      name: 'lessons',
      component: Lessons,
    },
    {
      path: '/collections/:id?/:searchList?',
      name: 'collections',
      component: Collections,
    },
    {
      path: '/scorms/:id',
      name: 'scorms',
      component: Scorms,
    },
    {
      path: '/scorm/:id/:packageType?/:packageId/:scormCode/:scormTitle/:scormDesc?',
      name: 'scorm',
      component: Scorm,
      props: true,
    },
    {
      path: '/assignment/details/:id/:community/:userId?',
      name: 'assignmentDetails',
      component: AssignmentDetails,
    },
    {
      path: '/assignment/create',
      name: 'assignmentCreate',
      component: AssignmentCreate,
    },
    {
      path: '/assignment/report',
      name: 'assignmentRepor',
      component: AssignmentReport,
    },
    // {
    //   path: '/speed',
    //   name: 'speedTest',
    //   component: SpeedTest,
    // },
  ],
});

export default router;

router.afterEach((to) => {
  if (to.name !== 'login') {
    if (!tools.cookie.get('access_token')) {
      router.push({ path: '/' });
    }
  }
  if (to.name === 'login') {
    if (tools.cookie.get('access_token')) {
      router.push({ path: '/home' });
    }
  }
});
