import store from '@/stores';

export default {
  cookie: {
    set(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${cname}=${cvalue};${expires};path=/`;
    },
    get(cname) {
      const name = `${cname}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
  },
  getCurrenDateAsLocale() {
    let now = new Date();
    let timezone = new Date().getTimezoneOffset();
    timezone *= -1;
    now = now.getTime() + timezone * 60000; // Milisaniye cinsinden toplama yapıldı
    now = new Date(now);
    return now;
  },
  setInternetSpeed() {
    const imageAddr = 'https://www.ueslms.com/speedtest/shutterstock_693112153.jpg';
    // const imageAddr = '../../static/img/31120037-5mb.jpg';
    let startTime;
    let endTime;
    const download = new Image();
    let success = false;
    let speed = 0;
    download.onload = () => {
      endTime = (new Date()).getTime();
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = 24189171 * 8;
      const speedBps = (bitsLoaded / duration).toFixed(2);
      const speedKbps = (speedBps / 1024).toFixed(2);
      const speedMbps = (speedKbps / 1024).toFixed(2);
      success = true;
      speed = speedMbps;
      store.dispatch('getInternet', {
        success,
        speed,
      });
    };
    download.onerror = (err, msg) => {
      console.log('ERR', err, msg);
      store.dispatch('getInternet', {
        success,
        speed,
      });
    };
    startTime = (new Date()).getTime();
    const cacheBuster = `?nnn=${startTime}`;
    download.src = imageAddr + cacheBuster;
  },
};
