import Vue from 'vue';
/* Custom Components */
import vSelect from 'vue-select';
import VJstree from 'vue-jstree';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
/* ----------------- */
// import { sync } from 'vuex-router-sync';
import router from '@/router';
import store from '@/stores';
import App from './App';

/* Custom Components */
Vue.component('v-select', vSelect);
Vue.component('v-jstree', VJstree);
Vue.component('datetime', Datetime);
/* ----------------- */

/* Bootstrap */
require('bootstrap/dist/css/bootstrap.min.css');
require('bootstrap/dist/js/bootstrap.min.js');

const isLocal = window.location.hostname === 'localhost';

if (isLocal) {
  window['ga-diasble-UA-127427549-9'] = true;
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
