<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">
        <div class="col-md-4" v-if="categories.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedCategory" v-html="categoriesOptString" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-4" v-if="themes.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedTheme" v-html="themesOptString" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div v-if="categories.length > 0 && themes.length > 0" class="col-md-4">
          <div :class="$style.divDdl">
            <Search :class="$style.divSearch"></Search>
          </div>
        </div>
        <div v-else class="col-md-4 offset-md-4">
            <Search></Search>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h1 class="title menuTitle" :class='$style.menuTitle'>{{title}}</h1>
          <hr>
        </div>
      </div>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div class="row" :class="$style.columns" v-else-if="scormData.length > 0">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" :class="$style.column" v-for="(item,i) in this.scormData" :key="i">
          <Card 
          :coverImageHeight='coverImgHeight' 
          contentType="scorm" 
          :id='item.id' 
          :scormTitle='item.name' 
          :imgpath="item.big_image ? item.big_image : item.image"
          :scormCode='item.scorm_code' 
          :scormDesc='item.scorm_details1' 
          :packageId='0'>
          </Card>
        </div>
      </div>
      <div v-else>
        <p :class="$style.noContent">There is no content here.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import Card from '@/components/Card';
  import Search from '@/components/Search';

  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'lessons',
    data() {
      return {
        scormData: [],
        linkData: [],
        title: '',
        ready: false,
        themes: [],
        categories: [],
        themesOptString: '',
        categoriesOptString: '',
        selectedTheme: 0,
        selectedCategory: 0,
        searchText: '',
        coverImgHeight: null,
      };
    },
    components: {
      HeaderCustom,
      Card,
      Search,
      InnerLoading,
    },
    computed: {
      ...mapState(['scorms']),
    },
    async beforeMount() {
      const collectionId = parseInt(this.$route.params.id, 10);
      if (collectionId === 46) {
        this.coverImgHeight = '240px';
      }
      this.title = this.$store.state.collections.find(x => x.id === collectionId).name;
      this.scormData = this.scorms.filter(x => x.collection_id === collectionId);
      this.fillDropdowns();
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
    methods: {
      ...mapActions(['getPackages']),
      search(text) {
        if (!text) {
          this.searchText = '';
          this.setPackages();
        } else {
          this.searchText = text.toLocaleLowerCase('tr-TR').trim();
          this.setPackages();
        }
      },
      setPackages() {
        /*eslint-disable*/
        const collectionId = parseInt(this.$route.params.id, 10);
        this.scormData = this.scorms.filter(x => x.collection_id === collectionId)
                                    .filter(x => this.searchText ? x.name.toLocaleLowerCase('tr-TR').includes(this.searchText) :
                                    true)
                                    .filter(x => Number(this.selectedTheme) !== 0 ? (x.scorm_theme !== null &&
                                                      x.scorm_theme.id === Number(this.selectedTheme)) :
                                                      true)
                                    .filter(x => Number(this.selectedCategory) !== 0 ? (x.scorm_category !== null &&
                                                      x.scorm_category.id === Number(this.selectedCategory)) :
                                                      true);
        this.fillDropdowns();
      },
      fillDropdowns() {
        //THEME DDL
        this.themes = _.uniqBy(this.scormData, 'scorm_theme.name').filter(x => x.scorm_theme && x.scorm_theme.id !== null).map(x => x.scorm_theme);
        this.themesOptString = '';
        this.themesOptString += '<option value="0">All Themes</option>';
        _.orderBy(this.themes, 'name').forEach((theme) => {
          const opt = `<option value='${theme.id}'>${theme.name}</option>`;
          this.themesOptString += opt;
        });
        //CATEGORY DDL
        this.categories = _.uniqBy(this.scormData, 'scorm_category.name')
                                  .filter(x => x.scorm_category && x.scorm_category.id !== null)
                                  .map(x => x.scorm_category);
        this.categoriesOptString = '';
        this.categoriesOptString += '<option value="0">All Categories</option>';
        _.orderBy(this.categories, 'name').forEach((category) => {
          const opt = `<option value='${category.id}'>${category.name}</option>`;
          this.categoriesOptString += opt;
        });
      },
    },
    watch: {
      selectedTheme() {
        this.setPackages();
      },
      selectedCategory() {
        this.setPackages();
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>
